<template>
	<div v-if="page" class="max-w-full overflow-hidden">
		<slot name="top">
			<nk-carousel variant="hero-slim">
				<template v-if="shuffledHeroImages">
					<nk-carousel-slide
						v-for="(heroImage, heroImageIndex) in shuffledHeroImages"
						:key="`heroImage-${heroImageIndex}`"
						:bg-image="heroImage.src"
						:bg-image-alt="heroImage.alt"
						:bg-image-copyright="heroImage.copyright"
						bg-image-copyright-position="left top"
					/>
				</template>
				<template v-else-if="defaultHeroImages">
					<nk-carousel-slide
						v-for="(defaultImage, defaultImageIndex) in defaultHeroImages"
						:key="`defaultImage-${defaultImageIndex}`"
						:bg-image="`/static-images/${defaultImage}`"
					/>
				</template>
			</nk-carousel>
			<nk-breadcrumb
				:breadcrumbs="page.breadcrumbs"
				:home-path="homePath"
				:home-title="homeTitle"
			/>
		</slot>

		<NkWrapper v-if="page.isLoading && !page.isQueryOnlyChanging" spacing="xl">
			<NkLoader color="primary" />
		</NkWrapper>
		<nk-container v-else>
			<nk-wrapper spacing="sm">
				<nk-row data-sticky-container gutter="10">
					<nk-col
						cols="full"
						:lg="
							emptySidebar && $slots.sidebar === undefined ? '10/12' : '8/12'
						"
					>
						<NkWrapper
							v-if="page.isLoading && page.isQueryOnlyChanging"
							spacing="xl"
						>
							<NkLoader color="primary" />
						</NkWrapper>
						<main v-else>
							<slot name="content">
								<template
									v-for="(module, indexModuleContent) in page.context.filter(
										module =>
											module.slotName === 'content' ||
											typeof module.slotName === 'undefined',
									)"
									:key="indexModuleContent"
								>
									<component
										v-bind="{
											context: module.context,
											options: module.options,
										}"
										:is="componentName(module.template)"
									/>
								</template>
							</slot>
						</main>
					</nk-col>
					<nk-col
						v-if="!emptySidebar || $slots.sidebar !== undefined"
						cols="full"
						lg="4/12"
						offset
					>
						<nk-sidebar>
							<slot name="sidebar">
								<template
									v-for="(module, indexModuleSidebar) in page.context.filter(
										module => module.slotName === 'sidebar',
									)"
									:key="indexModuleSidebar"
								>
									<component
										v-bind="{
											context: module.context,
											options: module.options,
										}"
										:is="componentName(module.template)"
									/>
								</template>
							</slot>
						</nk-sidebar>
					</nk-col>
				</nk-row>
			</nk-wrapper>
		</nk-container>
		<slot name="bottom" />
	</div>
</template>

<script>
import PageLayout from '#nk/mixins/page-layout';
import {useCMS} from '#nk/composables/cms';

export default {
	name: 'NkPageDefault',

	mixins: [PageLayout],

	props: {
		homePath: {
			type: String,
			required: false,
			default: '/',
		},
		homeTitle: {
			type: String,
			required: false,
			default: 'Startseite',
		},
	},

	setup() {
		const page = useCMS();

		return {
			page,
		};
	},
};
</script>
