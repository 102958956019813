import {
	NkImage,
	NkWidgetPushNotification,
	type NkPagination,
} from '#build/components';

const settings = {
	NkIcon: {
		icons: {
			'up-arrow-alt': ' bx-up-arrow-alt',
			'down-arrow-alt': ' bx-down-arrow-alt',
			'left-arrow-alt': ' bx-left-arrow-alt',
			'right-arrow-alt': ' bx-right-arrow-alt',
		},
		variants: {
			boxed:
				'p-2 mr-4 text-center rounded-full bg-primary-element hover:bg-primary-element--hover text-neutral-text-high-contrast hover:text-white',
		},
	},
	NkCarousel: {
		fixedClasses: {
			wrapper: 'nk-carousel-wrapper',
		},
		variants: {
			default: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: {
						delay: 2500,
					},
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
							centeredSlides: false,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
							centeredSlides: false,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				controls: 'flex justify-between items-center',
				buttonWrapper: '',
				button:
					'transition ease-out duration-300 justify-center items-center text-center text-secondary-element text-xl hover:text-secondary-element--hover focus:text-secondary-element--active hidden sm:inline-flex',
				buttonLeft: 'left-0',
				buttonRight: 'right-0',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5 mx-auto sm:mx-0',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded bg-primary-element hover:bg-primary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			gallery: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.5,
					centeredSlides: true,
					pagination: false,
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: '',
				slideContent:
					'bg-neurtral-surface h-full flex justify-center items-center relative z-0 overflow-hidden',
				slideBackgroundImage: '',
				buttonWrapper:
					'absolute top-1/2 left-0 w-full flex z-10 justify-between transform -translate-y-1/2 pointer-events-none px-6',
				button:
					'pointer-events-auto rounded transition ease-out duration-300 justify-center items-center text-center text-secondary-on-element text-xl inline-flex bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-5',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded bg-primary-element hover:bg-primary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			news: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 2.5,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: 'sm:overflow-hidden min-h-full h-full',
				sliderItem:
					'border-neutral-border border shadow-md rounded-lg min-h-full h-auto swiper-slide--news',
				slideContent: 'h-full relative overflow-hidden min-h-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 -mt-6 hidden p-2',
				button:
					'rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-primary-element hover:bg-primary-element--hover is-active:bg-secondary-element transition',
				paginationButtonActive: 'is-active',
			},
			calendar: {
				sliderOptions: {
					spaceBetween: 16,
					centeredSlides: false,
					slidesPerView: 1.25,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
							centeredSlides: false,
							navigation: false,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
							centeredSlides: false,
							navigation: false,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 36,
							centeredSlides: false,
							navigation: true,
						},
					},
				},
				wrapper: 'relative min-h-full h-full',
				slider: 'sm:overflow-hidden min-h-full h-full',
				sliderItem: '',
				slideContent:
					'h-full flex flex-col justify-between relative z-0 overflow-hidden min-h-full space-y-12 text-neutral-text-high-contrast group',
				slideBackgroundImage: '',
				buttonWrapper:
					'hidden sm:flex justify-center space-x-2 p-2 transform -translate-y-1/2 md:-mt-6',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-neutral-text-high-contrast hover:text-white focus:text-white bg-primary-element hover:bg-primary-element--hover focus:bg-primary-element--hover text-sm w-12 h-12',
				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled:
					'bg-white opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper: 'block my-3 sm:mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-primary-element hover:bg-primary-element--hover is-active:bg-secondary-element transition focus-visible:scale-[90%] focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			hero: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'slide',
					autoplay: {
						delay: 6000,
					},
					slideEffect: {
						shadow: true,
						translate: ['120%', 0, -500],
					},
				},
				wrapper:
					'relative w-full bg-secondary-surface aspect-[1/1] md:aspect-[3/2] lg:aspect-[16/9] xl:aspect-[21/9] max-h-screen overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[1/1] md:aspect-[3/2] lg:aspect-[16/9] xl:aspect-[21/9] max-h-screen',
				slideContent: 'relative z-1 h-full w-full',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: '',
				buttonWrapper:
					'absolute bottom-10 left-80 hidden sm:flex my-6 px-3 z-20 space-x-2 transform translate-y-1/2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-neutral-text-high-contrast hover:text-white focus:text-white bg-primary-500 hover:bg-primary-element--hover focus:bg-primary-element--hover hidden sm:inline-flex text-sm w-10 h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled:
					'bg-white opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full bottom-10 left-40 block z-10 my-6 transform translate-y-1/2',
				pagination: 'flex flex-wrap items-center list-none -mx-1 ',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-element transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},

			'hero-portal': {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'slide',
					autoplay: {
						delay: 6000,
					},
					slideEffect: {
						shadow: true,
						translate: ['120%', 0, -500],
					},
				},
				wrapper: 'relative w-full bg-secondary-surface',
				slider: 'h-full min-h-full',
				sliderItem:
					'flex items-center h-full aspect-[1/1] md:aspect-[3/2] lg:aspect-[16/9] xl:aspect-[21/9] max-h-screen',
				slideContent: 'relative z-1 h-full w-full',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: '',
				buttonWrapper:
					'absolute bottom-10 left-10 hidden sm:flex my-6 px-3 z-20 space-x-2 transform translate-y-1/2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-neutral-text-high-contrast hover:text-white focus:text-white bg-primary-500 hover:bg-primary-element--hover focus:bg-primary-element--hover hidden sm:inline-flex text-sm w-10 h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled:
					'bg-white opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute top-10 left-10 block z-10 transform -translate-y-1/2 ',
				pagination: 'flex flex-wrap items-center list-none -mx-1 ',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-element transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},

			topthema: {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 8000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'h-full min-h-full relative w-full overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem: 'h-full min-h-full',
				slideContent: 'relative z-10 h-full min-h-full',
				controls: '',
				buttonWrapper:
					'absolute top-0 right-0 px-3 translate-y-1/2 transform z-20 space-x-2',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-neutral-text-high-contrast hover:text-white focus:text-white bg-primary-500 hover:bg-primary-element--hover focus:bg-primary-element--hover hidden sm:inline-flex text-sm w-10 h-10',
				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled:
					'bg-white opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full bottom-0 block z-10 my-6 translate-y-1/2 transform',
				pagination: 'flex flex-wrap items-center list-none mx-3 justify-end ',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-primary-500/80 hover:bg-primary-500/100 is-active:bg-secondary-500 transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			'hero-slim': {
				sliderOptions: {
					spaceBetween: 0,
					slidesPerView: 1,
					speed: 600,
					centeredSlides: true,
					navigation: true,
					effect: 'fade',
					autoplay: {
						delay: 6000,
					},
					fadeEffect: {
						crossFade: true,
					},
				},
				wrapper: 'relative w-full bg-secondary-surface overflow-hidden',
				slider: 'h-full min-h-full',
				sliderItem: 'flex items-center h-full aspect-[21/9] max-h-[65vh]',
				slideContent:
					'relative z-10 py-10 px-3 max-w-screen-sm mx-auto text-center text-shadow-sm flex h-full items-center justify-center',
				slideBackgroundImage:
					'absolute top-0 left-0 object-cover lazyload h-full w-full',
				controls: '',
				buttonWrapper:
					'absolute bottom-0 right-20 hidden sm:flex my-6 px-3 z-20 space-x-2 ',
				button:
					'transition ease-out duration-300 justify-center items-center rounded-full text-center text-neutral-text-high-contrast hover:text-white focus:text-white bg-primary-500 hover:bg-primary-element--hover focus:bg-primary-element--hover hidden sm:inline-flex text-sm w-10 h-10',

				buttonLeftIcon: 'left-arrow-alt',
				buttonRightIcon: 'right-arrow-alt',
				buttonDisabled:
					'bg-white opacity-50 cursor-not-allowed pointer-events-none',
				paginationWrapper:
					'absolute w-full left-0 right-0 bottom-0 block z-10 my-6 -translate-y-1/2 transform',
				pagination:
					'flex flex-wrap justify-center items-center list-none -mx-1 ',
				paginationButton:
					'block w-4 is-active:w-16 h-4 mx-1 rounded-full bg-white/80 hover:bg-white/100 is-active:bg-secondary-element transition-all focus-visible:outline-white',
				paginationButtonActive: 'is-active',
			},
			bildergalerie: {
				sliderOptions: {
					spaceBetween: 16,
					slidesPerView: 1.25,
					centeredSlides: true,
					autoplay: false,
					initialSlide: 2,
					breakpoints: {
						640: {
							slidesPerView: 2,
							spaceBetween: 16,
						},
						768: {
							slidesPerView: 2,
							spaceBetween: 24,
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1280: {
							slidesPerView: 3,
							spaceBetween: 24,
						},
						1600: {
							slidesPerView: 4,
							spaceBetween: 24,
						},
					},
				},
				wrapper: 'relative',
				slider: 'sm:overflow-hidden',
				sliderItem: 'flex items-center justify-center',
				slideContent: 'h-full w-full',
				slideBackgroundImage: '',
				buttonWrapper:
					'w-full md:flex z-10 justify-end space-x-5 hidden -mt-4 p-2',
				button:
					'pointer-events-auto rounded-full transition ease-out duration-300 justify-center items-center text-center text-primary-text text-xl inline-flex border border-primary-element hover:bg-primary-element--hover hover:text-primary-on-element focus:bg-primary-element--active focus:text-primary-on-element',
				buttonLeft: '',
				buttonRight: '',
				buttonDisabled: 'opacity-50 cursor-not-allowed',
				paginationWrapper: 'block mt-10 p-2',
				pagination: 'flex justify-center md:justify-start list-none -mx-1',
				paginationButton:
					'block w-5 h-5 mx-1 rounded-full bg-primary-surface hover:bg-primary-surface--active is-active:bg-primary-element--active transition',
				paginationButtonActive: 'is-active',
			},
		},
	},
	NkCard: {
		variants: {
			default: {
				wrapper:
					'flex flex-col min-h-full h-full gap-3 overflow-hidden rounded-custom',
			},
			boxed: {
				wrapper:
					'flex flex-col min-h-full h-full justify-between bg-tertiary-200 flex flex-col overflow-hidden rounded-custom',
			},
			imageOverlay: {
				wrapper:
					'block relative overflow-hidden rounded-custom h-full min-h-full',
				body: 'lg:absolute lg:bottom-0 lg:left-0 w-full px-5 pb-6 pt-10 lg:text-white text-neutral-text lg:bg-gradient-to-t lg:from-black/75 lg:to-black/0 text-shadow-sm flex flex-col gap-3',
				header: 'h-full min-h-full',
				image: 'w-full h-full',
				footer: '',
				link: 'lg:!text-white transition transform duration-300 ease-out hover:-translate-y-1 hover:scale-[97%] focus-visible:scale-[97%] shadow-md shadow-md',
			},
			imagePreview: {
				body: 'flex flex-col gap-3 p-6 h-full',
				footer: 'mt-auto p-4 pt-0',
				header: '',
				image: '',
				link: 'transition duration-300 ease-out transform hover:-translate-y-1 hover:text-neutral-on-surface hover:bg-neutral-surface--active focus:text-neutral-on-surface focus:shadow-md focus:bg-neutral-surface--active',
				wrapper:
					'flex flex-col min-h-full h-full justify-between bg-tertiary-200 rounded-custom flex flex-col',
			},
		},
	},
	NkButton: {
		fixedClasses:
			'inline-flex items-center transition duration-300 ease-out font-medium relative rounded-full no-underline',
		disabled: 'opacity-50 cursor-not-allowed',
		expand: 'block w-full text-center justify-center',
		loading: 'relative',
		prependIconAttributes: {
			class: 'mr-1',
		},
		appendIconAttributes: {
			class: 'ml-1',
		},
		variants: {
			solid: 'nk-solid',
			outline: 'nk-outline border-2',
			link: 'nk-link border-b-2 rounded-none',
			ghost: 'nk-ghost',
		},
		colors: {
			primary:
				'[&.nk-solid]:bg-primary-300 [&.nk-solid]:hover:bg-primary-element [&.nk-solid]:focus:bg-primary-element [&.nk-solid]:text-neutral-text-high-contrast [&.nk-link]:border-primary-element [&.nk-link]:text-primary-text-high-contrast [&.nk-link]:hover:text-primary-text-high-contrast--hover [&.nk-link]:hover:border-primary-element--hover [&.nk-ghost]:text-primary-text-high-contrast [&.nk-ghost]:hover:text-primary-text-high-contrast--hover [&.nk-ghost]:hover:border-primary-element--hover [&.nk-outline]:border-primary-element [&.nk-outline]:text-primary-text-high-contrast [&.nk-outline]:hover:text-primary-text-high-contrast--hover [&.nk-outline]:hover:border-primary-element--hover',
			secondary:
				'[&.nk-solid]:bg-secondary-400 [&.nk-solid]:hover:bg-secondary-element [&.nk-solid]:focus:bg-secondary-element [&.nk-solid]:text-white [&.nk-link]:border-secondary-element [&.nk-link]:text-secondary-text [&.nk-link]:hover:text-secondary-text--hover [&.nk-link]:hover:border-secondary-element--hover [&.nk-ghost]:text-secondary-text [&.nk-ghost]:hover:text-secondary-text--hover [&.nk-ghost]:hover:border-secondary-element--hover [&.nk-outline]:border-secondary-element [&.nk-outline]:text-secondary-text [&.nk-outline]:hover:text-secondary-text--hover [&.nk-outline]:hover:border-secondary-element--hover',
			neutral:
				'[&.nk-solid]:bg-neutral-element [&.nk-solid]:hover:bg-neutral-element--hover [&.nk-solid]:focus:bg-neutral-element--active [&.nk-solid]:text-neutral-on-element [&.nk-link]:border-neutral-element [&.nk-link]:text-neutral-text [&.nk-link]:hover:text-neutral-text--hover [&.nk-link]:hover:border-neutral-element--hover [&.nk-ghost]:text-neutral-text [&.nk-ghost]:hover:text-secondary-text--hover [&.nk-ghost]:hover:border-neutral-element--hover [&.nk-outline]:border-neutral-element [&.nk-outline]:text-neutral-text-high-contrast [&.nk-outline]:hover:text-neutral-text-high-contrast--hover [&.nk-outline]:hover:border-neutral-element--hover',
			danger:
				'[&.nk-solid]:bg-danger-element [&.nk-solid]:hover:bg-danger-element--hover [&.nk-solid]:focus:bg-danger-element--active [&.nk-solid]:text-danger-on-element [&.nk-link]:border-danger-element [&.nk-link]:text-danger-text [&.nk-link]:hover:text-danger-text--hover [&.nk-link]:hover:border-danger-element--hover [&.nk-ghost]:text-danger-text [&.nk-ghost]:hover:text-danger-text--hover [&.nk-ghost]:hover:border-danger-element--hover [&.nk-outline]:border-danger-element [&.nk-outline]:text-danger-text [&.nk-outline]:hover:text-danger-text--hover [&.nk-outline]:hover:border-danger-element--hover',
			success:
				'[&.nk-solid]:bg-success-element [&.nk-solid]:hover:bg-success-element--hover [&.nk-solid]:focus:bg-success-element--active [&.nk-solid]:text-success-on-element [&.nk-link]:border-success-element [&.nk-link]:text-success-text [&.nk-link]:hover:text-success-text--hover [&.nk-link]:hover:border-success-element--hover [&.nk-ghost]:text-success-text [&.nk-ghost]:hover:text-success-text--hover [&.nk-ghost]:hover:border-success-element--hover [&.nk-outline]:border-success-element [&.nk-outline]:text-success-text [&.nk-outline]:hover:text-success-text--hover [&.nk-outline]:hover:border-success-element--hover',
			info: '[&.nk-solid]:bg-info-element [&.nk-solid]:hover:bg-info-element--hover [&.nk-solid]:focus:bg-info-element--active [&.nk-solid]:text-info-on-element [&.nk-link]:border-info-element [&.nk-link]:text-info-text [&.nk-link]:hover:text-info-text--hover [&.nk-link]:hover:border-info-element--hover [&.nk-ghost]:text-info-text [&.nk-ghost]:hover:text-info-text--hover [&.nk-ghost]:hover:border-info-element--hover [&.nk-outline]:border-info-element [&.nk-outline]:text-info-text [&.nk-outline]:hover:text-info-text--hover [&.nk-outline]:hover:border-info-element--hover',
			warning:
				'[&.nk-solid]:bg-warning-element [&.nk-solid]:hover:bg-warning-element--hover [&.nk-solid]:focus:bg-warning-element--active [&.nk-solid]:text-warning-on-element [&.nk-link]:border-warning-element [&.nk-link]:text-warning-text [&.nk-link]:hover:text-warning-text--hover [&.nk-link]:hover:border-warning-element--hover [&.nk-ghost]:text-warning-text [&.nk-ghost]:hover:text-warning-text--hover [&.nk-ghost]:hover:border-warning-element--hover [&.nk-outline]:border-warning-element [&.nk-outline]:text-warning-text [&.nk-outline]:hover:text-warning-text--hover [&.nk-outline]:hover:border-warning-element--hover',
			white:
				'[&.nk-solid]:bg-white [&.nk-solid]:hover:bg-primary-element [&.nk-solid]:hover:text-primary-text-high-contrast--hover [&.nk-solid]:focus:text-primary-text-high-contrast--active [&.nk-solid]:text-primary-text-high-contrast [&.nk-link]:border-white-element [&.nk-link]:text-white-text [&.nk-link]:hover:text-white [&.nk-link]:hover:border-white [&.nk-ghost]:text-white [&.nk-ghost]:hover:text-white [&.nk-ghost]:hover:border-white-element--hover [&.nk-outline]:border-white-element [&.nk-outline]:text-white-text [&.nk-outline]:hover:text-white-text--hover [&.nk-outline]:hover:border-white-element--hover',
		},
		sizes: {
			default: 'px-3 py-2 min-h-6 text-base',
			sm: 'px-3 py-2 text-sm',
			md: 'p-3 text-md',
			lg: 'p-3 text-lg',
			collapse: 'p-0',
		},
	},
	NkBox: {
		variants: {
			default: 'bg-tertiary-200 p-6 mb-3',
			transparent: 'p-6',
			sidebar: 'bg-tertiary-200 p-6 rounded-custom',
		},
	},
	NkDrilldown: {
		fixedClasses: '',
		variants: {
			default: {
				menu: 'nk-drilldown bg-neutral-surface rounded overflow-hidden',
				menuInner: 'list-none',
				menuItem: 'border-b border-neutral-border last:border-0',
				menuItemButton:
					'relative flex w-full py-4 px-4 text-primary-text-high-contrast hover:bg-neutral-surface-hover border-l-4 border-l-primary-500',
				menuItemButtonActive:
					'border-b border-neutral-border text-primary-text-high-contrast border-l-8 border-l-primary-500',
				menuItemIcon: 'no-underline before:no-underline after',
				menuItemButtonIcon:
					'ml-auto no-underline absolute right-0 mr-3 transform transition-transform duration-300 ease-in-out ',
				menuItemButtonIconActive: 'rotate-180',
				menuItemLink:
					'block py-4 px-4 link-active:bg-primary-200 link-active:text-primary-text-high-contrast--active link-active-exact:bg-primary-200 link-active-exact:text-primary-text-high-contrast--active group-[.is-submenu]:pl-8',
				menuItemLinkIcon: 'ml-auto no-underline before:no-underline',
				submenu: 'bg-neutral-surface--active group is-submenu',
			},
		},
	},
	NkPagination: {
		classes: {
			button:
				'inline-flex rounded-full p-2 items-center justify-center bg-primary-element text-neutral-text hover:bg-primary-element--hover hover:text-white focus:bg-primary-element--active focus:text-white',
			buttonDisabled:
				'rounded-full inline-flex border-solid border border-neutral-border p-2 items-center justify-center cursor-not-allowed',
			currentItem: '',
			item: 'text-sm px-1',
			link: 'inline-block text-type text-center hover:text-primary-text--hover focus:text-primary-text--active p-2',
			linkActive: 'inline-block text-center text-primary-500 font-bold p-2',
			list: 'flex items-center list-none -mx-1',
			wrapper: 'my-3',
		},
	},
	NkNav: {
		variants: {
			default: {
				wrapper: '',
				item: 'border-b border-neutral-border text-md',
				title: 'font-bold',
				text: '',
				content: 'inline-block text-base group-hover:underline ',
				link: 'block py-3 px-2 transiton duration-300 group text-primary-text-high-contrast hover:text-primary-text-high-contrast--hover link-active:bg-primary-surface link-active:rounded-full',
				prependIcon: {
					icon: 'right-arrow-alt',
					class:
						'p-2 mr-4 text-center rounded-full bg-primary-element hover:bg-primary-element--hover text-neutral-text-high-contrast hover:!text-white',
				},
			},
		},
	},
	NkImage: {
		classes: {
			container:
				'block relative bg-neutral-surface w-full rounded-custom overflow-hidden',
			copyright:
				'inline-block absolute z-30 opacity-75 leading-none rounded-full p-3 text-white text-sm',
			figcaption: 'h-auto relative italic my-2 text-base',
			image:
				'max-w-full w-full lazyload transition-opacity duration-500 ease-out delay-100',
			noteLabel:
				'absolute bottom-0 right-0 inline-flex items-center bg-black bg-opacity-75 m-2 px-2 py-1 text-white text-sm rounded whitespace-no-wrap',
			noteLabelIcon: 'mr-1 text-base',
			wrapper: 'relative',
		},
	},
	NkLink: {
		classes:
			'underline text-primary-text-high-contrast hover:text-primary-text-high-contrast--hover focus:text-primary-text-high-contrast--active',
	},
	NkWidgetPushNotification: {
		variants: {
			floating: {
				button:
					'pointer-events-auto bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active text-secondary-on-element rounded-full p-3 leading-none shadow-lg',
			},
			inline: {
				button:
					'pointer-events-auto bg-secondary-element hover:bg-secondary-element--hover focus:bg-secondary-element--active text-secondary-on-element rounded-full p-3 leading-none',
			},
		},
	},
	NkBreadcrumb: {
		homeIcon: {
			color: 'text-primary-text-high-contrast',
			variant: 'boxed',
		},
		classes: {
			link: 'text-primary-text-high-contrast inline-flex items-center align-middle link-active-exact:text-neutral-text',
			wrapper: 'container mx-auto my-2 px-3',
		},
	},
};

export {settings};
